<template>

  <div>

    <div class="top_style">
      <img alt="Vue logo" src="../../../public/logo.png" style="width: 42px; height: 42px;margin-left: 15px">
      <span class="span_center">Lucky Maker</span>

      <div style="width: 100%;">
        <div style="float: right;margin-right: 15px;">
      
          <span class="tab_type" @click="jumpHome">Home</span>
          <span class="tab_type" @click="jumpAboutUs" style="margin-left: 30px;">About Us</span>
          <span class="tab_type" @click="jumpContactUs" style="margin-left: 30px;color: #FFCA12">Contact Us</span>

        </div>
      </div>
    </div>


    <div style="overflow: hidden;height: 100%;width: 100%;background-color: #F2F7FE;position: fixed">

      <div style="width: 100%; text-align: center">
        <p class="span_center" style="margin-top: 180px;color: #602CCD;font-size:45px">Contact Us</p>
        <p style="margin-top: 30px;color: #4F4F4F;font-weight: bold;">Company:</p>
        <p style="margin-top: 0px;color: #4F4F4F;">FOX INFORMATION PTE. LTD. </p>
        <p style="margin-top: 30px;color: #4F4F4F;font-weight: bold;">Location: </p>
        <p style="margin-top: 0px;color: #4F4F4F;">160 ROBINSON ROAD #20-03 SINGAPORE (068914)</p>
        <p style="margin-top: 30px;color: #4F4F4F;font-weight: bold;">EMAIL: </p>
        <p style="margin-top: 0px;color: #4F4F4F;">support@luckymaker.com</p>
      </div>
    </div>

    <div style="width:100%; background: #2F1A5E;text-align: center;position: absolute;bottom: 0%;padding-bottom: 30px;padding-top:30px;">
        <div class="flex_center" style="margin-left: 80px;margin-right: 80px;">
            <div style="width:50%;text-align: left;">
                <p class="tab_type" style="color: #FFCA12">Company:</p>
                <p class="tab_type" >FOX INFORMATION PTE. LTD. </p>

                <p class="tab_type" style="color: #FFCA12; margin-top:30px;">LOCATION:</p>
                <p class="tab_type" >160 ROBINSON ROAD #20-03 SINGAPORE (068914)</p>

                <p class="tab_type" style="color: #FFCA12; margin-top:30px;">EMAIL:</p>
                <p class="tab_type" >support@luckymaker.com</p>
            </div>

            <div style="width:50%;text-align: left;">
                <p class="tab_type" >Company:</p>
                <p class="tab_type" @click="jumpAboutUs" style="color:#FFCA12;margin-top:10px;">>> About Us</p>
                <p class="tab_type" @click="jumpContactUs" style="color: #FFCA12;margin-top:10px;">>> Contact Us</p>
                <p class="tab_type" @click="jumpPrivacyPolicy" style="color: #FFCA12;margin-top:10px;">>> Privacy Policy</p>
                <p class="tab_type" @click="jumpUsePolicy" style="color: #FFCA12;margin-top:10px;">>> Terms & Condition</p>
            </div>
        </div>
        <div class="tab_type" style="color: #FFCA12;margin-top:30px;">© 2023. All rights reserved by FOX INFORMATION PTE. LTD.</div>
    </div>

  </div>

</template>

<script>
export default {
  name: "index",

  data() {
    return {
      screenWidth: document.body.clientWidth,     // 屏幕宽
      screeHeight: document.body.clientHeight,    // 屏幕高

    }
  },
  created() {
    this.calculateSize();
    window.addEventListener('resize', this.calculateSize);
  },
  mounted() {
    let self = this;
    setTimeout(function () {
      self.calculateSize();
    }, 500);
  },
  methods: {
    calculateSize() {

    },
    /**
     * 打开首页
     */
    jumpHome() {
      this.$router.push({
        path: '/',
      });
    },
    /**
     * 打开关于我们界面
     */
    jumpAboutUs() {
      this.$router.push({
        path: '/aboutUs',
      });
    },
    /**
     * 打开联系我们界面
     */
    jumpContactUs() {
      this.$router.push({
        path: '/contactUs',
      });
    },
    /**
     * 打开隐私协议
     */
    jumpPrivacyPolicy() {
      this.$router.push({
        path: '/PrivacyAgreement',
      });
    },
    /**
     * 打开用户协议
     */
    jumpUsePolicy() {
      this.$router.push({
        path: '/UserAgreement',
      });
    },
  },
}
</script>

<style scoped>

.sp_type {
  color: white;
  font-family: Arial;
  font-size: 36px;
  color: white;
  font-weight: bold;
  margin: 130px 0px 0px 25px;
}

.wrap-content {
  overflow: auto;
  position: relative;
  font-size: 20px;
  background-color: white;
}

.phone_style {
  position: absolute;
  top: 100px;
  /*实现垂直居中*/
  /*align-items: center;*/
  /*!*实现水平居中*!*/
  justify-content: center;

  /*display: table-cell;*/
  /*vertical-align: middle;*/
}

.btn_bg_div {
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid white;
  outline: none;
  color: #ffffff;
  background: transparent;
  box-sizing: border-box;
  font-size: 0.93em;
  padding: 10px;

  /*flex 布局*/
  display: flex;
  /*实现垂直居中*/
  align-items: center;
}

.left_btn_bg {
  cursor: pointer;
  border-radius: 10px;
  border: 0px solid transparent;
  outline: none;
  color: #ffffff;
  background: linear-gradient(140deg, #F7D34B, #6131C2, #623BEF);
  box-sizing: border-box;
  font-size: 0.93em;
  width: 180px;
  height: 60px;

  /*flex 布局*/
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  margin-top: 10px;
}

.right_btn_bg {
  cursor: pointer;
  outline: none;
  color: #ffffff;
  box-sizing: border-box;
  font-size: 0.93em;
  width: 100%;
  height: 40px;
  border-width: 0px;
  background: linear-gradient(78deg, #6131C4, #623BF0);
  border-radius: 25px;

  /*!*flex 布局*!*/
  /*display: flex;*/
  /*!*实现垂直居中*!*/
  /*align-items: center;*/
}

.img_w_h {
  width: 372px;
  height: 430px;
}

.center_div {
  width: 372px;
  height: 430px;
  display: table;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;
}

/*.triangle {*/
/*  width: 100%;*/
/*  height: 0;*/
/*  border-top: 100px solid #8B60F0;*/
/*  border-left: 100px solid transparent;*/
/*}*/

.triangle {
  width: 100%;
  height: 0;
  border-top: 400px solid #8B60F0;
  /*border-right: 1800px solid transparent;*/
}

.bottom_triangle {
  width: 100%;
  height: 0;
  border-bottom: 360px solid #8B60F0;
  /*border-left: 1800px solid transparent;*/
}

.top_style {
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  padding: 10px;
  background: linear-gradient(78deg, #6131C4, #623BF0);
}

.tab_type {
  font-size: 16px;
  color: white;
  font-family: Arial;
}

.span_center {
  width: 100%;
  font-weight: bold;
  font-size: 26px;
  color: white;
  margin-left: 10px;
}

.flex_center {
  /*flex 布局*/
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;

  text-align: justify;
  /*width:200px;*/
  /*height:200px;*/
  /*background: #000;*/
  margin: 0 auto;
  color: #fff;
}


</style>